e<script>
import Layout from "../../layouts/course";
import { api } from "@/state/services";
import Courses from "@/components/widgets/loading-courses";

export default {
  locales: {
    pt: {
      'Category': 'Categoria',
      'Buy': 'Comprar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.',
      'No records found.': 'Nenhum registro encontrado.',
    },
    es: {
      'Category': 'Categoría',
      'Buy': 'Comprar',
      'We apologize, we are unable to retrieve information at this time. Please try again later.': 'Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'No records found.': 'No se encontraron registros.',
    }
  },
  components: { Layout, Courses },
  data() {
    return {
      category: {
        page: 1,
        pages: 0,
        loading: true,
        errored: false,
        empty: false,
        id: this.$route.params.id,
        name: '...',
        content: '',
      },
    };
  },
  methods: {
    getCourses() {
      this.category.errored = false
      this.category.loading = true
      this.category.empty = false
      this.category.name = '...'
      this.category.pages = 0;

      api
        .get('course/categories/' + this.category.id + '?page='+this.category.page)
        .then(response => {
          if (response.data.status=='success') {
            this.category.name = response.data.category.name
            this.category.content = response.data.list
            this.category.pages = response.data.pages
            this.category.loading = false;
            this.category.empty = false;
          } else if (response.data.status=='empty'){
            this.category.name = response.data.category.name
            this.category.content = []
            this.category.pages = 0;
            this.category.loading = false
            this.category.empty = true
          }
        })
        .catch(error => {
          this.category.errored = error
          this.category.loading = false
          this.category.empty = false
        })
    }
  },
  mounted() {
    this.getCourses()
  },
  watch: {
    '$route.params.id': function (v) {
      this.category.id = v
      this.category.page = 1
      this.getCourses()
    },
    'category.page': function() {
      this.getCourses();
    }
  }
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ category.name }} </h4>
        </div>
      </div>
    </div>

    <div v-if="category.errored">
      <div class="card">
        <div class="card-body">
          {{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}
        </div>
      </div>
    </div>
    <div v-else-if="category.empty">
      <div class="card">
        <div class="card-body">
          {{ t('No records found.') }}
        </div>
      </div>
    </div>
    <div v-else>
      <div v-if="category.loading" class="row">
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12"><Courses class=""/></div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12"><Courses class=""/></div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12"><Courses class=""/></div>
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12"><Courses class=""/></div>
      </div>
      <div v-else class="row">
        <div class="col-xl-4 col-lg-6 col-md-6 col-xs-12" v-for="(courses, index) in category.content" :key="index">
          <template v-if="courses.module == 'advanced'">
            <router-link tag="a" :to="'/courses/' + courses.id" class="text-dark">
              <div class="card p-2" style="box-shadow: 2px 2px 1px 0px rgba(34, 35, 58, 0.1);">
                <div class="d-flex align-items-center h-100">
                  <div>
                    <div class="thumb" :style="'background-image: url(' + courses.thumbnail +');'"></div>
                  </div>
                  <div class="pt-0 p-2 pl-3 font-weight-bold title">
                    {{ courses.title }}
                  </div>
                </div>
              </div>
            </router-link>
          </template>
          <template v-else>
            <router-link tag="a" :to="'/course/' + courses.id + '/preview'" class="text-dark">
              <div class="card p-2" style="box-shadow: 2px 2px 1px 0px rgba(34, 35, 58, 0.1);">
                <div class="d-flex align-items-center h-100">
                  <div>
                    <div class="thumb" :style="'background-image: url(' + courses.thumbnail +');'"></div>
                  </div>
                  <div class="pt-0 p-2 pl-3 font-weight-bold title">
                    {{ courses.title }}
                  </div>
                </div>
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </div>
    <paginate
      v-if="!category.errored && !category.empty && category.pages > 1"
      v-model="category.page"
      :page-count="category.pages"
      :page-range="3"
      :margin-pages="2"
      prev-text="<i class='mdi mdi-chevron-left'></i>"
      next-text="<i class='mdi mdi-chevron-right'></i>"
      :container-class="'pagination pagination-rounded justify-content-center mt-4'"
      :page-class="'page-item'"
      :page-link-class="'page-link'"
      :prev-link-class="'page-link'"
      :next-link-class="'page-link'"
      :break-view-link-class="'page-link'">
    </paginate>
  </Layout>
</template>

<style lang="css" scoped>
  .title {
    line-height: 15px;
  }

  .thumb {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    width: 150px;
    height: 120px;
  }
</style>